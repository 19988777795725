import axios from 'axios'

const api = axios.create({
  baseURL: import.meta.env.VITE_APP_BASE_URL, // Laravel API base URL
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
  withCredentials: true, // Include this if you are using cookies for auth
})

api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token')
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

export default api
