<template>
  <svg :height="props.height" alt="ABO TRACKER" fill="none" viewBox="0 0 478 57" xmlns="http://www.w3.org/2000/svg">
    <text x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" font-size="34" font-weight="700" fill="WHITE">
      ABO TRACKER
    </text>
  </svg>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    height?: number
    start?: string
    end?: string
  }>(),
  {
    height: 18,
    start: 'primary',
    end: undefined,
  },
)

/* const colorsComputed = computed(() => {
  return {
    start: getColor(props.start),
    end: getColor(props.end || props.start),
  }
}) */
</script>
