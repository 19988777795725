export const sleep = (ms = 0) => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

/** Validation */
export const validators = {
  email: (v: string) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return pattern.test(v) || "S'il vous plaît, mettez une adresse email valide"
  },
  required: (v: any) => !!v || 'Ce champ est obligatoire',
  isNumber: (value: any) => !isNaN(Number(value)) || 'Doit être un nombre',
}
