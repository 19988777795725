import api from './api'
interface LoginData {
  email: string
  password: string
}

export const login = async (data: LoginData) => {
  try {
    const response = await api.post('/auth/login', data)

    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const register = async (data) => {
  try {
    const response = await api.post('/auth/register', data)
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}
export const logout = async () => {
  try {
    const response = await api.post('/auth/logout')
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    return response
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}
export const confirmAccount = async (email, code) => {
  try {
    const response = await api.post('/auth/confirm', { email, code })
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}
export const forgetPassword = async (data: LoginData) => {
  try {
    const response = await api.post('/auth/password/forgot', data)

    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}
export const resetPassword = async (data: {
  email: string
  password: string
  password_confirmation: string
  token: string
}) => {
  try {
    const response = await api.post('/auth/password/reset', data)
    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}
export const getUserConnected = async () => {
  try {
    const response = await api.get(`user`)

    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}

export const updateUserConnected = async (data: any) => {
  try {
    const response = await api.patch(`user`, data)

    return response.data
  } catch (error) {
    throw error.response ? error.response.data : error
  }
}
