import { Ref, ref, unref, watch } from 'vue'
import {
  getNotifications,
  getAllNotifications,
  getLastNotifications,
  updateLastNotificationsView,
  updateNotification,
  addNotification,
  removeNotification,
  updateAllstatus,
  getAbonnement,
  Filters,
  Pagination,
  Sorting,
} from '../../../data/pages/notifications'
import { Notification, Abonnement } from '../types'
import { watchIgnorable } from '@vueuse/core'

const makePaginationRef = () => ref<Pagination>({ page: 1, perPage: 10, total: 0 })
const makeSortingRef = () => ref<Sorting>({ sortBy: 'title', sortingOrder: null })
const makeFiltersRef = () => ref<Partial<Filters>>({ isActive: true, search: '' })

export const useNotifications = (options?: {
  pagination?: Ref<Pagination>
  sorting?: Ref<Sorting>
  filters?: Ref<Partial<Filters>>
}) => {
  const isLoading = ref(false)
  const notifications = ref<Notification[]>([])
  const allNotifications = ref<Notification[]>([])
  const countNotifications = ref(0)
  const abonnements = ref<Abonnement[]>([])

  const { filters = makeFiltersRef(), sorting = makeSortingRef(), pagination = makePaginationRef() } = options || {}

  const fetch = async () => {
    isLoading.value = true
    const { data, pagination: newPagination } = await getNotifications({
      ...unref(filters),
      ...unref(sorting),
      ...unref(pagination),
    })

    notifications.value = data

    ignoreUpdates(() => {
      pagination.value = newPagination
    })

    isLoading.value = false
  }

  const { ignoreUpdates } = watchIgnorable([pagination, sorting], fetch, { deep: true })

  watch(
    filters,
    () => {
      pagination.value.page = 1
      fetch()
    },
    { deep: true },
  )

  const fetchAbonnements = async () => {
    const abo = await getAbonnement()
    abonnements.value = abo.data.data
  }

  const fetchAllNotifications = async () => {
    isLoading.value = true
    const response = await getAllNotifications()
    allNotifications.value = response.data.data
    isLoading.value = false
  }

  const fetchLastNotifications = async () => {
    isLoading.value = true
    const response = await getLastNotifications()
    allNotifications.value = response.data.data
    countNotifications.value = response.data.data.length
    isLoading.value = false
  }

  const updateNotificationsView = async () => {
    isLoading.value = true
    await updateLastNotificationsView()
    isLoading.value = false
  }

  fetch()
  fetchAbonnements()
  fetchAllNotifications()

  return {
    isLoading,
    filters,
    sorting,
    pagination,
    notifications,
    allNotifications,
    countNotifications,
    abonnements,
    fetch,
    fetchAllNotifications,
    fetchLastNotifications,
    updateNotificationsView,
    async add(notification: Notification) {
      isLoading.value = true
      await addNotification(notification)
      await fetch()
      isLoading.value = false
    },
    async update(notification: Notification) {
      isLoading.value = true
      await updateNotification(notification)
      await fetch()
      isLoading.value = false
    },
    async updateAllstatus(notification: Notification) {
      isLoading.value = true
      await updateAllstatus(notification)
      await fetch()
      isLoading.value = false
    },
    async remove(notification: Notification) {
      isLoading.value = true
      await removeNotification(notification)
      await fetch()
      isLoading.value = false
    },
  }
}
