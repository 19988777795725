import { sleep } from '../../services/utils'
import { Notification } from '../../pages/notifications/types' // Update path and type name as per your project structure
import api from '../../services/api' // Assuming notifications-db.json contains your notifications data

let notifications: Notification[] = []

export type Pagination = {
  page: number
  perPage: number
  total: number
}

export type Sorting = {
  sortBy: keyof Notification | undefined // Adjust to Notification type
  sortingOrder: 'asc' | 'desc' | null
}

export type Filters = {
  isActive: number
  search: string
}

const getSortItem = (obj: any, sortBy: string) => {
  // Adjust sorting logic based on Notification properties
  if (sortBy === 'projects') {
    return obj.projects.map((project: any) => project.project_name).join(', ')
  }

  return obj[sortBy]
}

export const initializeNotifications = async () => {
  const Notifs = await api.get(`notificationsSettings`)
  notifications = Notifs.data.data
}

export const getNotifications = async (filters: Partial<Filters & Pagination & Sorting>) => {
  await sleep(1000)
  const { isActive, search, sortBy, sortingOrder } = filters

  const status = isActive ? 1 : 0
  initializeNotifications()

  let filteredNotifications = notifications

  filteredNotifications = filteredNotifications.filter((notification) => notification.status === status)

  if (search) {
    filteredNotifications = filteredNotifications.filter(
      (notification) =>
        notification.description.toLowerCase().includes(search.toLowerCase()) ||
        notification.subscriptionTrackerName.toLowerCase().includes(search.toLowerCase()),
    )
  }

  if (sortBy && sortingOrder) {
    filteredNotifications = filteredNotifications.sort((a, b) => {
      const first = getSortItem(a, sortBy)
      const second = getSortItem(b, sortBy)
      if (first > second) {
        return sortingOrder === 'asc' ? 1 : -1
      }
      if (first < second) {
        return sortingOrder === 'asc' ? -1 : 1
      }
      return 0
    })
  }

  const { page = 1, perPage = 10 } = filters || {}
  return {
    data: filteredNotifications.slice((page - 1) * perPage, page * perPage),
    pagination: {
      page,
      perPage,
      total: filteredNotifications.length,
    },
  }
}

export const getNotification = async (notification: Notification) => {
  await sleep(1000)
  const index = notifications.findIndex((n) => n.id === notification.id)
  return notifications[index]
}

export const addNotification = async (notification: Notification) => {
  await api.post(`createNotification`, notification)
  await sleep(1000)
  notifications.unshift(notification)
  const getNotif = await api.get(`notificationsSettings`)
  notifications = getNotif.data.data
}

export const updateNotification = async (notification: Notification) => {
  await sleep(1000)
  const index = notifications.findIndex((n) => n.id === notification.id)
  const id_notification = notification.id
  const getNotif = await api.patch(`notificationsUpdate/${id_notification}`, notification)
  notifications[index] = getNotif.data.data
}

export const updateAllstatus = async (notification: Notification) => {
  await sleep(1000)
  const getNotif = await api.patch(`updateAllStatus`, notification)
  notifications = getNotif.data.data
}

export const updateLastNotificationsView = async () => {
  const updateNotificationsView = await api.patch(`updateLastNotifications`)
  return updateNotificationsView
}

export const getAllNotifications = async () => {
  const allNotifications = await api.get(`notificationsSettings`)
  return allNotifications
}

export const getLastNotifications = async () => {
  const allNotifications = await api.get(`getLastNotifications`)
  return allNotifications
}

export const getAbonnement = async () => {
  const abonnement = await api.get(`subscription-trackers`)
  return abonnement
}

export const removeNotification = async (notification: Notification) => {
  await sleep(1000)
  notifications.splice(
    notifications.findIndex((n) => n.id === notification.id),
    1,
  )
  const id_notification = notification.id
  await api.delete(`deleteNotification/${id_notification}`)
  const getNotif = await api.get(`notificationsSettings`)
  notifications = getNotif.data.data
}
