export interface INavigationRoute {
  name: string
  displayName: string
  meta: { icon: string }
  children?: INavigationRoute[]
}

export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'menu.dashboard',
      meta: {
        icon: 'vuestic-iconset-dashboard',
      },
    },
    /*   {
      name: 'users',
      displayName: 'menu.users',
      meta: {
        icon: 'group',
      },
    }, */
    {
      name: 'abonnements',
      displayName: 'menu.abonnements',
      meta: {
        icon: 'credit_card',
      },
    },
    {
      name: 'stats',
      displayName: 'menu.stats',
      meta: {
        icon: 'mso-monitoring',
      },
    },
    {
      name: 'calendar',
      displayName: 'menu.calendar',
      meta: {
        icon: 'vuestic-iconset-files',
      },
    },
  ] as INavigationRoute[],
}
