import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import AuthLayout from '../layouts/AuthLayout.vue'
import AppLayout from '../layouts/AppLayout.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: () => {
      const isAuthenticated = !!localStorage.getItem('token') // Exemple d'authentification basée sur un token
      if (isAuthenticated) {
        return { name: 'dashboard' }
      } else {
        return { name: 'login' }
      }
    },
  },
  {
    name: 'admin',
    path: '/app',
    component: AppLayout,
    redirect: { name: 'dashboard' },
    meta: { requiresAuth: true },
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('../pages/admin/dashboard/Dashboard.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'settings',
        path: 'settings',
        component: () => import('../pages/settings/Settings.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'preferences',
        path: 'preferences',
        component: () => import('../pages/preferences/Preferences.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'users',
        path: 'users',
        component: () => import('../pages/users/UsersPage.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'abonnements',
        path: 'abonnements',
        component: () => import('../pages/abonement/AbonnementPage.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'stats',
        path: 'stats',
        component: () => import('../pages/stat/BudgetPage.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'calendar',
        path: 'calendar',
        component: () => import('../pages/calendar/MonthList.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'notifications',
        path: 'notifications',
        component: () => import('../pages/notifications/NotificationsPage.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'params',
        path: 'params/notification',
        component: () => import('../pages/notifications/NotificationsPage.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'alerts',
        path: 'alerts',
        component: () => import('../pages/alertes/AlertesPage.vue'),
        meta: { requiresAuth: true },
      },
      {
        name: 'projects',
        path: 'projects',
        component: () => import('../pages/projects/ProjectsPage.vue'),
        meta: { requiresAuth: true },
      },
    ],
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: '/login',
        component: () => import('../pages/auth/Login.vue'),
      },
      {
        name: 'signup',
        path: '/signup',
        component: () => import('../pages/auth/Signup.vue'),
      },
      {
        name: 'recover-password',
        path: '/recover-password',
        component: () => import('../pages/auth/RecoverPassword.vue'),
      },
      {
        name: 'forget-password',
        path: '/password/reset/:token',
        component: () => import('../pages/auth/SendNewPassword.vue'),
        props: (route) => ({ token: route.params.token, email: route.query.email }),
      },
      {
        name: 'recover-password-email',
        path: '/recover-password-email',
        component: () => import('../pages/auth/CheckTheEmail.vue'),
      },
      {
        name: 'confirmation-compte',
        path: '/confirmation-compte',
        component: () => import('../pages/auth/ConfirmCompte.vue'),
      },
    ],
  },
  {
    name: '404',
    path: '/404',
    component: () => import('../pages/404.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { el: to.hash, behavior: 'smooth' }
    } else {
      window.scrollTo(0, 0)
    }
  },
  routes,
})

// Middleware pour vérifier l'authentification
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('token') // Exemple d'authentification basée sur un token
  if (to.matched.some((record) => record.meta.requiresAuth) && !isAuthenticated) {
    next({ name: 'login' })
  } else {
    next()
  }
})

export default router
